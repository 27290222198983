import React, { useMemo } from "react";

import {
  Button,
  ButtonProps,
  ButtonIcon,
  IconMagnifyingGlass
} from "@busbud/horizon";

import { useLiteAppContext } from "@app/helpers/hooks";

type CompactConfiguration = Partial<
  Record<"xs" | "sm" | "md" | "lg" | "xl", boolean>
>;

interface SearchFormButtonProps {
  id: string;
  label: string;
  isHighlighted: boolean;
  isSubmit?: boolean;
  compact?: CompactConfiguration;
}

const default_entity: Record<string, ButtonProps> = {
  mobile: {
    size: "lg",
    appearance: "primary",
    iconStart: <IconMagnifyingGlass size="md" />
  },
  tablet: {
    size: "xl",
    appearance: "primary"
  },
  desktop: {
    size: "xl",
    appearance: "primary",
    iconStart: <IconMagnifyingGlass size="lg" />
  }
} as const;

const BaseSearchFormButton = ({
  id,
  label,
  compact,
  isSubmit,
  isHighlighted
}: SearchFormButtonProps) => {
  const { liteTranslator, entity } = useLiteAppContext();
  const { button_size } = toSizes(compact ?? {});

  const button_title = liteTranslator.t("!landing.input-label.search");

  const results_entity = useMemo(() => {
    const config: Record<string, ButtonProps> = {
      mobile: {
        size: "lg",
        appearance: "primary",
        iconStart: <IconMagnifyingGlass size="md" />
      },
      tablet: {
        appearance: "primary"
      },
      desktop: {
        size: {
          initial: "lg",
          xl: "xl"
        },
        appearance: "primary",
        iconStart: <IconMagnifyingGlass size="lg" />
      }
    };

    if (!isHighlighted) {
      config.mobile.appearance = "default";
      delete config.tablet.appearance;
      config.tablet.variant = "text";
      delete config.desktop.appearance;
      config.desktop.variant = "text";
    }

    return config;
  }, [isHighlighted]);

  const type = entity?.type === "results" ? results_entity : default_entity;

  return (
    <>
      <span className="h-full sm:hidden">
        <Button
          id={id + "-xs"}
          className="h-full w-full"
          ariaLabel={button_title}
          isSubmit={isSubmit}
          {...type.mobile}
        >
          {label ?? button_title}
        </Button>
      </span>
      <span className="hidden h-full sm:inline md:hidden">
        <ButtonIcon
          id={id + "-icon"}
          ariaLabel={button_title}
          className="h-full w-full rounded-bl-none rounded-tl-none"
          size={button_size}
          isSubmit={isSubmit}
          {...type.tablet}
        >
          <IconMagnifyingGlass size="lg" />
        </ButtonIcon>
      </span>
      <span className="hidden h-full md:block">
        <Button
          id={id + "-md"}
          className="h-full w-full rounded-bl-none rounded-tl-none xl:rounded-bl-none xl:rounded-tl-none"
          ariaLabel={button_title}
          isSubmit={isSubmit}
          {...type.desktop}
        >
          {label ?? button_title}
        </Button>
      </span>
    </>
  );
};
export const SearchFormButton = React.memo(BaseSearchFormButton);

// convert compactConfiguration to buttonProps.size
function toSizes(compact: CompactConfiguration): {
  button_size: ButtonProps["size"];
} {
  return {
    button_size: {
      initial: "lg",
      sm: compact.sm ? "lg" : "xl",
      md: compact.md ? "lg" : "xl",
      lg: compact.lg ? "lg" : "xl",
      xl: compact.xl ? "lg" : "xl"
    }
  };
}
