import { lazySentryCaptureException } from "@app/helpers/sentry-capture";
import { BrowserStorage } from "@app/lib/bowser-storage/browser-storage";
import { SearchPlace } from "@app/types/search-types";

import { CitySuggestionTemplate } from "../autocomplete/autocomplete-client";

type Source = "origin" | "destination";

const RECENT_SEARCHES_MAX_QUEUE_SIZE = 3;
export const recent_searches_keys: Record<Source, string> = {
  origin: "recent_searches_origin",
  destination: "recent_searches_destination"
};

export function saveRecentSearch(
  browserStorage: BrowserStorage,
  source: Source,
  suggestion: CitySuggestionTemplate | null
) {
  try {
    const saved_recent_searches =
      browserStorage.getItem<CitySuggestionTemplate[]>(
        recent_searches_keys[source]
      ) || [];

    saveSearch(
      recent_searches_keys[source],
      saved_recent_searches,
      suggestion,
      browserStorage
    );
  } catch (error) {
    lazySentryCaptureException(error);
  }
}

function isSuggestionSearchAlreadySaved(
  saved_recent_searches: CitySuggestionTemplate[],
  suggestion: CitySuggestionTemplate
) {
  return saved_recent_searches.some(search => {
    return (
      (!!suggestion.location &&
        search.location?.geo_entity_id ===
          suggestion.location?.geo_entity_id) ||
      search.city_id === suggestion.city_id
    );
  });
}

export function isPlaceSearchAlreadySaved(
  saved_recent_searches: CitySuggestionTemplate[],
  place: SearchPlace
) {
  return saved_recent_searches.some(search => {
    return (
      (!!place.location &&
        search.location?.geo_entity_id === place.location?.geo_entity_id) ||
      search.city_id === place.city?.id
    );
  });
}

function saveSearch(
  recent_searches_key: string,
  saved_recent_searches: CitySuggestionTemplate[],
  suggestion: CitySuggestionTemplate | null,
  browser_storage: BrowserStorage
): void {
  if (!suggestion) {
    return;
  }

  const is_search_already_saved = isSuggestionSearchAlreadySaved(
    saved_recent_searches,
    suggestion
  );

  if (is_search_already_saved) {
    return;
  }

  if (saved_recent_searches.length >= RECENT_SEARCHES_MAX_QUEUE_SIZE) {
    saved_recent_searches.shift();
  }

  saved_recent_searches.push(suggestion);
  browser_storage.setItem(recent_searches_key, saved_recent_searches);
}
