export class IdleValue<A> {
  private value: A | undefined = undefined;
  private idle_handle: number | undefined = undefined;
  public constructor(private initValue: () => A) {
    if (typeof window !== "undefined" && "requestIdleCallback" in window) {
      this.idle_handle = requestIdleCallback(() => {
        this.value = this.initValue();
      });
    }
  }

  public getValue(): A {
    if (this.value === undefined) {
      if (this.idle_handle !== undefined) {
        cancelIdleCallback(this.idle_handle);
      }
      this.value = this.initValue();
    }
    return this.value;
  }
}
