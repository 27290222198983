/*eslint-disable no-console */

import { sprintf, vsprintf } from "sprintf-js";

import type { ValidLiteTranslationKey } from "./translator-lite.keys";
import type { ValidTranslationKey } from "./translator.keys";

import { lazySentryCaptureException } from "../helpers/sentry-capture";

export const BADSPRINTF = "!BADSPRINTF";

function noop() {}

type TranslatorKind = "full" | "lite";

interface TranslatorWithKind<Kind extends TranslatorKind> {
  __type: Kind | undefined;
}

type BaseTranslatorService<
  T extends string,
  Kind extends TranslatorKind = "full"
> = {
  t(key: T, variables?: any): string;
  setLangEntries(phrases: Record<T, string>): void;
  unsafeT(key: string, variables?: any): string;
} & {
  __type: Kind | undefined;
};

export type TranslatorService = BaseTranslatorService<
  ValidTranslationKey,
  "full"
>;

/**
 * The lite translator includes a limited set of translations for the client side.
 * see `@app/services/translator-lite.keys.ts` for the list of available keys.
 */
export type LiteTranslatorService = BaseTranslatorService<
  ValidLiteTranslationKey,
  "lite"
>;

type TranslationKeyOptions = ValidTranslationKey | ValidLiteTranslationKey;
type DefaultTranslationKey = ValidTranslationKey;
type Phrases<T extends TranslationKeyOptions = DefaultTranslationKey> = Record<
  T,
  string
>;

export interface TranslatorI18N<
  T extends TranslationKeyOptions = DefaultTranslationKey
> {
  [locale: string]: {
    phrases: Phrases<T>;
    terms_phrases: Phrases<T>;
  };
}

export default class Translator<
    T extends TranslationKeyOptions = DefaultTranslationKey,
    Kind extends TranslatorKind = "full"
  >
  implements BaseTranslatorService<T>, TranslatorWithKind<Kind>
{
  // This forces a disjointed type to be used so that lite translator cannot be passed when expecting a full translator
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  __type = undefined;

  public constructor(
    private phrases: Record<T, string> = {} as Record<T, string>
  ) {}

  public setLangEntries(phrases: typeof this.phrases): void {
    this.phrases = phrases;
  }

  public t(key: T, ...args: unknown[]): string {
    this._logger("key-used", key);

    var string = this._getTranslationString(key);

    // Inject variables when a type specifier is present in string
    if (string.match(/%[bcdieufgosxXj]|%\([^)]+\)s|%\d\$s/)) {
      return this._injectVariables(key, string, args);
    }

    return string;
  }

  /**
   * Same as `t` but does not check for type specifiers in the string.
   *
   * @param key The translation key
   * @param args The variables
   * @returns
   */
  public unsafeT(key: string, ...args: unknown[]): string {
    let string = key;
    try {
      this._logger("key-used", key);
      string = this._getTranslationString(key as T);

      // Inject variables when a type specifier is present in string
      if (string.match(/%[bcdieufgosxXj]|%\([^)]+\)s|%\d\$s/)) {
        return this._injectVariables(key, string, args);
      }
    } catch (error) {
      this._sentryLogger("unsafeT", key);
    }

    return string;
  }

  private _getTranslationString(key: T): string {
    if (!Object.prototype.hasOwnProperty.call(this.phrases, key)) {
      this._logger("missing-key", key);

      // Don't allow e2e tests to pass with missing translations
      if (globalThis.__ENV__ === "test") {
        throw new Error(`Missing translation for key: ${key}`);
      }

      return key;
    }

    return this.phrases[key];
  }

  private _injectVariables(
    key: string,
    string: string,
    args: unknown[]
  ): string {
    if (args.length === 0) {
      this._logger(
        `bad-sprintf: No variables provided for injection. Key: ${key}, string: ${string}`,
        key
      );

      return BADSPRINTF;
    }

    // Use vsprintf for array arguments
    if (args.length === 1 && Array.isArray(args[0])) {
      try {
        return vsprintf(string, args[0]);
      } catch (e) {
        this._logger(
          `bad-vsprintf: Error while translating. Key: ${key}, string: ${string}`,
          key
        );
        return BADSPRINTF;
      }
    }

    try {
      return sprintf(string, ...args);
    } catch (e) {
      this._logger(
        `bad-sprintf: Error while translating. Key: ${key}, string: ${string}`,
        key
      );
      return BADSPRINTF;
    }
  }

  public _logger(type: string, key: string): void {
    if (!globalThis.__DEV__) {
      return this._sentryLogger(type, key);
    }

    if (globalThis.__CLIENT__) {
      return this._clientLogger(type, key);
    } else {
      return this._serverLogger(type, key);
    }
  }

  public _clientLogger(type: string, key: string): void {
    if (type !== "key-used") {
      console.error(`Translator:${type} => ${key}`);
    }
  }

  public _serverLogger(type: string, key: string): void {
    if (!globalThis.__CLIENT__ && globalThis.__DEV__) {
      import("fs").then(fs => {
        fs.appendFile(`translator.${type}.log`, `${key}\n`, noop);
      });
    }
  }

  public _sentryLogger(type: string, key: string): void {
    if (type !== "key-used") {
      lazySentryCaptureException(new Error(`Translator Error: ${type}`), {
        tags: { translation_key: key }
      });
    }
  }

  /**
   * Only useful for tests :(
   */
  public _getPhrases(): Record<string, string> {
    return this.phrases;
  }
}
