import React, { Ref, useContext } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Calendar } from "@app/components/calendar";
import { IsoDate } from "@app/components/calendar/Calendar.types";
import {
  closeModal,
  useIsOutboundDateModalOpen,
  useIsReturnDateModalOpen
} from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-modal-state";
import { LiteAppContext } from "@app/context/lite-app-context";
import {
  setOutboundDate,
  setReturnDate
} from "@app/modules/search/store/slices/search-form";

import CalendarAdapter from "../../modules/search/calendar/calendar-adapter";
import { MAX_SEARCH_DISTANCE_MONTHS } from "../../modules/search/constants";
import { formatToLocalDate } from "../../utils/dates-without-moment";
import { AdaptiveSelectorContainer } from "../adaptive-selector-container";

interface Props {
  selected_date?: string | null;
  direction?: "outbound" | "return";
  input_element?: Ref<HTMLInputElement>;
  now?: Date;
}

export const CalendarModal: React.FC<Props> = ({
  selected_date,
  input_element,
  direction = "outbound",
  now = new Date()
}) => {
  const { locale, liteTranslator } = useContext(LiteAppContext);
  const dispatch = useDispatch();
  const state_outbound_date = useSelector(
    (state: any) => state.search_form.outbound_date
  );

  // today's date in the current timezone
  const today = formatToLocalDate(now);

  const adapter = React.useMemo(() => {
    const calendarAdapter = new CalendarAdapter(locale, liteTranslator, today);

    return {
      getToday: () => calendarAdapter.getToday(),
      addDays: (date: string, nbDaysToAdd: number) =>
        calendarAdapter.addDays(date, nbDaysToAdd),
      getWeekdays: () => calendarAdapter.getWeekdays(),
      getMonthYear: (date: IsoDate) => calendarAdapter.getMonthYear(date),
      getWeekArray: (date: IsoDate) => calendarAdapter.getWeekArray(date),
      translations: calendarAdapter.getTranslations(),
      getDateOrClosestDateInFuture: (date: IsoDate) =>
        calendarAdapter.getDateOrClosestDateInFuture(date)
    };
  }, [locale, liteTranslator, today]);

  const title =
    direction === "outbound"
      ? liteTranslator.t("!search.calendar.outbound-title")
      : liteTranslator.t("!search.calendar.return-title");

  const initial_date = selected_date
    ? adapter.getDateOrClosestDateInFuture(selected_date)
    : undefined;

  const handleSelectedOutbound = (date: string) => {
    dispatch(setOutboundDate(date));
    closeModal();
  };
  const handleSelectedReturn = (date: string) => {
    dispatch(setReturnDate(date));
    closeModal();
  };

  const isReturnOpen = useIsReturnDateModalOpen();
  const isOutBoundOpen = useIsOutboundDateModalOpen();

  const handleSelectedDate =
    direction === "outbound" ? handleSelectedOutbound : handleSelectedReturn;

  return (
    <AdaptiveSelectorContainer
      onClose={closeModal}
      anchor_element={input_element}
      dialog_title={title}
      isOpen={direction === "outbound" ? isOutBoundOpen : isReturnOpen}
    >
      <Calendar
        showRange={direction === "return"}
        id={`${direction}-calendar`}
        data-testid={`${direction}-calendar`}
        selectedDate={initial_date}
        state_outbound_date={state_outbound_date}
        maxMonthsDisplay={MAX_SEARCH_DISTANCE_MONTHS}
        minSelectableDate={
          (direction === "return" && state_outbound_date) || today
        }
        adapter={adapter}
        onDateSelection={handleSelectedDate}
        deselectableDate={direction === "return"}
      />
    </AdaptiveSelectorContainer>
  );
};

export default CalendarModal;
