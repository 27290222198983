import { LocationType } from "@busbud/int-schemas/udm";

import {
  PlacesResponse,
  Suggestion,
  SuggestionParent,
  SuggestionTemplate
} from "@app/modules/search/autocomplete/autocomplete-client";

const TEMPLATE = {
  CITY: "city",
  LOCATION: "location",
  POINT_OF_INTEREST: "point-of-interest"
};

enum PlaceType {
  Location = "location",
  PointOfInterest = "point-of-interest",
  City = "city"
}

export interface FormattedSuggestion {
  id: string;
  place_id?: string;
  city_id: string;
  full_name: string;
  geohash: string;
  place_type?: PlaceType;
  geo_entity_id?: string;
  template: SuggestionTemplate;
  country_code2: string;
  region_code: string;
  city: string;
  country: string;
  timezone?: string;
  city_url?: string;
  location?: {
    country: string;
    full_name: string;
    id: string[];
    stop_type: string;
    geo_entity_id?: string;
    place_type: PlaceType;
    country_code2: string;
    region_code: string;
    city_id?: string;
  };
}

/**
 * Formats flex suggestion responses to be compatible with autocomplete
 * 1. Removes tracking ids appended by Flex
 * 2. Groups suggestions by cities to provide nesting effect in autocomplete.js
 * 3. Flattens grouped suggestions into an array as autocomplete.js expects it to be
 *
 * @param places_response
 * @returns
 */
export function formatSuggestions(
  places_response: PlacesResponse
): FormattedSuggestion[] {
  const suggestions_by_city = groupSuggestionsByCity(places_response);
  return flattenSuggestions(suggestions_by_city);
}

const stop_type_ranking: Record<string, number> = {
  [LocationType.Airport]: 1,
  [LocationType.TrainStation]: 2,
  [LocationType.BusStation]: 3
};

function getStopTypeRanking(stop_type: string) {
  return stop_type_ranking[stop_type] || Infinity;
}

/**
 * Groups suggestions by city to provide nesting effect in autocomplete.js
 *
 * @param places_response
 * @returns
 */
function groupSuggestionsByCity(
  places_response: PlacesResponse
): CitySuggestion[] {
  const { suggestions, parents, siblings } = places_response;
  const suggestions_by_city: CitySuggestion[] = [];

  for (const suggestion of suggestions) {
    if (suggestion.place_type === PlaceType.City) {
      const id = suggestion.place_id;

      const city_index = findCityIndex(suggestions_by_city, id!);
      // check whether city is already added to the array
      // if not found, initialize the city
      if (city_index === -1) {
        const city_suggestion = createCitySuggestion(
          suggestion,
          id!,
          suggestion.url,
          suggestion.request_id ?? null,
          true
        );

        suggestions_by_city.push(city_suggestion);
      } else {
        suggestions_by_city[city_index].include_in_suggestions = true;
      }
    }

    if (
      [PlaceType.Location, PlaceType.PointOfInterest].includes(
        suggestion.place_type as PlaceType
      ) &&
      suggestion.parent_id
    ) {
      const city_index = findCityIndex(
        suggestions_by_city,
        suggestion.parent_id
      );

      const location_suggestion = createPointOfInterestAndLocationSuggestion(
        suggestion,
        siblings
      );

      if (city_index !== -1) {
        // check whether city is already added to the array
        // if found, add the location to the city
        const city_suggestion = suggestions_by_city[city_index];
        city_suggestion.locations.push(location_suggestion);
      } else {
        const parent_suggestion = parents[suggestion.parent_id];
        parent_suggestion.hierarchy_info = suggestion.hierarchy_info;

        const city_suggestion = createCitySuggestion(
          parent_suggestion,
          suggestion.parent_id,
          suggestion.url,
          suggestion.request_id ?? null,
          false
        );
        city_suggestion.locations.push(location_suggestion);
        suggestions_by_city.push(city_suggestion);
      }
    }
  }

  suggestions_by_city.map(suggestion => {
    suggestion.locations.sort((a, b) =>
      getStopTypeRanking(a.stop_type) < getStopTypeRanking(b.stop_type) ? -1 : 0
    );
  });

  return suggestions_by_city;
}

/**
 * Flattens suggestions grouped by city into a 1D array
 * and determines the template of each suggestion
 *
 * @param suggestions_by_city
 * @returns
 */
function flattenSuggestions(
  suggestions_by_city: CitySuggestion[]
): FormattedSuggestion[] {
  const flattened_suggestions: FormattedSuggestion[] = [];

  for (const suggestion of suggestions_by_city) {
    if (suggestion.locations.length === 0) {
      // if city does not have any locations
      // push the city - template is city
      flattened_suggestions.push(
        addTemplateTagToSuggestion(suggestion, TEMPLATE.CITY)
      );
    } else if (
      suggestion.locations.length === 1 &&
      !suggestion.include_in_suggestions
    ) {
      // if city has one location where the city wasn't part of the results.
      // push the city with location - template is city
      flattened_suggestions.push(
        addTemplateTagToSuggestion(
          suggestion,
          TEMPLATE.CITY,
          suggestion.locations[0]
        )
      );
    } else {
      // if city has more than one locations
      // push the city - template is city
      flattened_suggestions.push(
        addTemplateTagToSuggestion(suggestion, TEMPLATE.CITY)
      );
      for (const location of suggestion.locations) {
        // then iterate through the locations
        // push each location - template is location
        flattened_suggestions.push(
          addTemplateTagToSuggestion(suggestion, TEMPLATE.LOCATION, location)
        );
      }
    }
  }

  return flattened_suggestions;
}

function findCityIndex<T extends { id: string }>(
  suggestions_by_city: T[],
  id: string | null
): number {
  return suggestions_by_city.findIndex(city => city.id === id);
}

type LocationSuggestion = {
  full_name: string;
  id: string[];
  geo_entity_id?: string;
  stop_type: string;
  place_type: PlaceType;
  country_code2: string;
  country: string;
  region_code: string;
  city_id?: string;
};

type CitySuggestion = {
  country_code2: string;
  region_code: string;
  city_url: string;
  full_name: string;
  geohash: string;
  /** uuid from geo-service **/
  geo_entity_id?: string;
  place_type?: PlaceType;
  locations: LocationSuggestion[];
  id: string;
  include_in_suggestions: boolean;
  request_id: string | null;
  city_id: string;
  country: string;
  city: string;
  timezone?: string;
};

function createCitySuggestion(
  suggestion: SuggestionParent,
  id: string,
  city_url: string,
  request_id: string | null,
  include_in_suggestions: boolean = true
): CitySuggestion {
  return {
    id,
    city_id: id,
    city_url,
    city: suggestion.city_name,
    country: suggestion.hierarchy_info?.country?.name || "" || "",
    country_code2: suggestion.hierarchy_info?.country?.code || "",
    region_code: suggestion.hierarchy_info?.region?.code || "",
    geohash: suggestion.geohash,
    geo_entity_id: suggestion.geo_entity_id,
    place_type: suggestion.place_type || PlaceType.City,
    full_name: suggestion.full_name,
    locations: [],
    include_in_suggestions,
    request_id: request_id || null
  };
}

function createPointOfInterestAndLocationSuggestion(
  suggestion: Suggestion,
  siblings: Record<string, string[]>
): LocationSuggestion {
  const { id, place_id, place_type, geo_entity_id, stop_type, full_name } =
    suggestion;

  return {
    id: siblings?.[id] ?? (place_id !== undefined ? [place_id] : []),
    geo_entity_id: geo_entity_id,
    place_type,
    stop_type,
    full_name,
    country_code2: suggestion.hierarchy_info?.country?.code || "",
    country: suggestion.hierarchy_info?.country?.name || "",
    region_code: suggestion.hierarchy_info?.region?.code || ""
  };
}

function addTemplateTagToSuggestion(
  suggestion: CitySuggestion,
  template: SuggestionTemplate,
  location?: LocationSuggestion
): FormattedSuggestion {
  // Omit locations and include_in_suggestions property from the return object
  // by destructuring locations and the rest from suggestion
  const { locations, include_in_suggestions, ...rest } = suggestion;

  return {
    ...rest,
    ...(location ? { location } : null),
    template // template tag is used by autocomplete.js to determine which design template to use
  };
}
