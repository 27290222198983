// Tracks the HTML tag lang attribute changes. Usefull to know if user a using any translation tool per example the Google Chrome translation tool.
const TrackTranslate = {
  init() {
    const _win: Window = window;

    try {
      const htmlTag = _win.document.getElementsByTagName("html")[0];

      const onMutate: MutationCallback = mutationsList => {
        const mutatedLang = mutationsList.find(
          mutated => mutated.attributeName === "lang"
        );

        if (mutatedLang) {
          _win.tracker.asyncTrack({
            action: "translate",
            trigger: "browser",
            details: {
              translated_to: htmlTag.getAttribute("lang")
            }
          });
        }
      };

      const observer = new MutationObserver(onMutate);
      observer.observe(htmlTag, { attributes: true });
    } catch (e) {
      // Prevent Sentry to catch possible errors due to unsuported browsers
    }
  }
};

export default TrackTranslate;
