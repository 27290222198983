import React, { useContext } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Label, Select } from "@busbud/horizon";

import { LiteAppContext } from "@app/context/lite-app-context";
import { VALIDATE_FORM_MESSAGE } from "@app/modules/search/constants";
import { setPassengerAge } from "@app/modules/search/store/slices/search-form";
import {
  ADULT,
  CHILD,
  CHILD_AGES,
  SENIOR,
  SENIOR_AGES,
  PassengersCount,
  AGE_RANGES
} from "@app/modules/search/store/slices/search-form/passengers-constants";
import { LiteTranslatorService } from "@app/services/translator";
import { LandingPageReduxState } from "@app/types/landing-page";
import { ResultsReduxState } from "@app/types/results-redux-types";
import { range } from "@app/utils/functional";

type Props = {
  category: Exclude<PassengersCount, typeof ADULT>;
};

export const PassengerAge: React.FC<Props> = props => {
  const { liteTranslator } = useContext(LiteAppContext);
  const { passengers } = useSelector(
    (state: LandingPageReduxState | ResultsReduxState) => state.search_form
  );
  const dispatch = useDispatch();

  const { category } = props;
  const category_ages = getCategoryAges(category);

  const items: React.ReactNode[] = [];
  for (let i = 0; i < passengers[category]; i++) {
    const label_id = `label-${category}-${i}`;

    items.push(
      <div key={label_id} className="flex items-center justify-between gap-150">
        <Label as="span">{getLabel(category, liteTranslator, i + 1)}</Label>

        <Select
          id={label_id}
          className="w-1400 shrink-0"
          labelText={liteTranslator.t("!landing.input-label.age-placeholder")}
          defaultValue={passengers[category_ages]?.[i]}
          isRequired
          isInvalid={typeof passengers[category_ages]?.[i] !== "number"}
          size="sm"
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            const age = parseInt(event.target.value);
            dispatch(
              setPassengerAge({
                category,
                age,
                index: i
              })
            );
            // send a message that vanilla javascript can listen to
            if (typeof window !== "undefined") {
              window.postMessage(VALIDATE_FORM_MESSAGE);
            }
          }}
        >
          {buildOptions(category, liteTranslator)}
        </Select>
      </div>
    );
  }

  if (items.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col gap-150 rounded-lg bg-color-film-secondary p-150 ps-200 outline outline-width-lg outline-color-film-secondary">
      {items}
    </div>
  );
};

const getCategoryAges = (category: Exclude<PassengersCount, typeof ADULT>) =>
  category === CHILD ? CHILD_AGES : SENIOR_AGES;

const getLabel = (
  category: Exclude<PassengersCount, typeof ADULT>,
  translator: LiteTranslatorService,
  number: number
) => {
  switch (category) {
    case CHILD:
      return translator.t("!landing.input-label.youth-number", {
        number
      });
    case SENIOR:
      return translator.t("!landing.input-label.senior-age", {
        number
      });
  }
};

const getAgeLabel = (age: number, translator: LiteTranslatorService) => {
  switch (age) {
    case 0:
      return translator.t("!landing.input-label.age-0");
    case 80:
      return translator.t("!landing.input-label.age-80");
    default:
      return age;
  }
};

const buildOptions = (
  category: "child" | "senior",
  translator: LiteTranslatorService
) => {
  const { min, max } = AGE_RANGES[category];

  const options = range(min, max + 1).map((age: number) => (
    <Select.Option key={age} value={age}>
      {getAgeLabel(age, translator)}
    </Select.Option>
  ));

  return [options];
};
