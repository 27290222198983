/**
 * Tracks the visibility of elements with the `data-view-block-tracking-name` attribute.
 *
 * Triggers a "view_section" event when the top or bottom edge of an element enters the viewport.
 * The event details include the page type and position (`above_{block_name}` or `below_{block_name}`),
 * where `block_name` is derived from the `data-view-block-tracking-name` attribute.
 *
 * Example:
 * ```jsx
 * <ContentBlock data-view-block-tracking-name="faq" />
 * ```
 *
 * The observer stops tracking the element after the event is triggered.
 */
const TrackVisibleElements = {
  init: function () {
    try {
      const on_observe: IntersectionObserverCallback = entries => {
        entries.forEach(entry => {
          const block_name = (entry.target as HTMLElement).dataset
            .viewBlockTrackingName;
          const details = (entry.target as HTMLElement).dataset
            .viewBlockTrackingDetails;

          let parsed_details;
          try {
            parsed_details = details?.length && (JSON.parse(details) as object);
          } catch (error) {
            parsed_details = undefined;
          }

          if (block_name) {
            const action = block_name;
            const page = window.BB.entity?.type; // Assuming BB is globally available

            const track_top = {
              action,
              details: {
                page,
                ...parsed_details
              }
            };

            // Check if the element has entered the viewport
            if (entry.intersectionRatio > 0) {
              if (entry.boundingClientRect.top >= 0) {
                window.tracker.asyncTrack(track_top);
              }

              observer.unobserve(entry.target);
            }
          }
        });
      };

      const observer = new IntersectionObserver(on_observe);

      window.document
        .querySelectorAll("[data-view-block-tracking-name]")
        .forEach((selector: Element) => observer.observe(selector));
    } catch (e) {
      // IE is not compatible, this avoid console + sentry errors
    }
  }
};

export default TrackVisibleElements;
