import { AmplitudeEvent } from "@app/types/tracking/common";
import {
  ClickedRouteCalendarDeparture,
  ClickedRouteCalendarMore,
  ClickedRouteCalendarTab
} from "@app/types/tracking/price-calendar";

import { TrackingAction } from "./constants/tracking-action";

export function clickedRouteCalendarTab(
  has_price: boolean,
  index: number
): AmplitudeEvent<ClickedRouteCalendarTab> {
  return {
    action: TrackingAction.ClickedRouteCalendarTab,
    details: {
      has_price: has_price,
      day_selected: index
    }
  };
}

export function clickedRouteCalendarMore(
  index: number
): AmplitudeEvent<ClickedRouteCalendarMore> {
  return {
    action: TrackingAction.ClickedRouteCalendarMore,
    details: {
      day_selected: index
    }
  };
}

export function clickedRouteCalendarDeparture(
  index: number,
  annotation?: string
): AmplitudeEvent<ClickedRouteCalendarDeparture> {
  return {
    action: TrackingAction.ClickedRouteCalendarDeparture,
    details: {
      day_selected: index,
      annotation
    }
  };
}
