import React, { Children } from "react";

import { defaultFallbackInView } from "react-intersection-observer";

import { LiteAppContextProvider } from "@app/context/lite-app-context";
import { WhitelabelClientContextProvider } from "@app/context/whitelabel-context";
import { BBType } from "@app/types/bb-types";

import templates from "../../../config/link-templates";
import Translator, { TranslatorI18N } from "../translator";
import { ValidLiteTranslationKey } from "../translator-lite.keys";
import LiteUrlBuilderService from "../url-builders/lite-url-builder";

/**
 * The useInView hook uses IntersectionObserver API under the hook.
 * Some old browsers do not have this API implemented which is causing an error to be thrown and the react app to crash.
 * This function call set the default value returned by the hook if the IntersectionObserver API is not implemented.
 * Setting it to true means that every elements are displayed in the view port so everything should be mounted in the DOM.
 */
defaultFallbackInView(true);

interface LiteServiceProviderClientProps {
  BB: BBType;
  I18N: TranslatorI18N<ValidLiteTranslationKey>;
}

export const LiteServiceProviderClient = ({
  BB,
  I18N,
  children
}: React.PropsWithChildren<LiteServiceProviderClientProps>) => {
  const { entity, config, device, features } = BB;
  const { locale } = config;

  const liteTranslator = new Translator<ValidLiteTranslationKey>(
    I18N[locale].phrases
  );

  const tracker = window.tracker;

  const urlBuilder = new LiteUrlBuilderService({ locale, templates });

  const context = {
    entity: entity || null,
    features,
    device,
    locale,
    liteTranslator,
    tracker,
    urlBuilder
  };
  return (
    <WhitelabelClientContextProvider BB={BB}>
      <LiteAppContextProvider value={context}>
        {Children.only(children)}
      </LiteAppContextProvider>
    </WhitelabelClientContextProvider>
  );
};
