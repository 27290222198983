import { VehicleType } from "@busbud/int-schemas";

import { AffiliatePartners } from "@app/constants/affiliate-partners";
import { VehicleCategoryKey } from "@app/modules/results/state/filters-types";
import {
  getResultsPageUrl,
  getSearchAffiliateQuery
} from "@app/modules/search/helpers/submit";
import { redirectToAffiliatePartner } from "@app/tracking/search-tracking";
import { AmplitudeGtmTracker } from "@app/tracking/tracker";
import { Features } from "@app/types/experiments";
import { PublicInterface } from "@app/types/meta-types";
import { SearchFormMergedState } from "@app/types/search-types";
import {
  isRedirectTargetAvailable,
  openAndFocusOn,
  redirect
} from "@app/utils/window";

import { landing_pages_entities_trains_types } from "@data/entity-types";

interface ResultsPageRedirectOptions {
  lang: string;
  features: Partial<Features>;
  state: Omit<SearchFormMergedState, "passengers">;
  whitelabel: string | undefined;
  tracker: PublicInterface<AmplitudeGtmTracker> | null;
}

export const openToResultsPage = ({
  state,
  ...options
}: ResultsPageRedirectOptions): void => {
  const results_page_url = getResultsPageUrl(options, state);
  openAndFocusOn(results_page_url);
};

const getVehicleCategory = (
  state_vehicle_category: VehicleCategoryKey | undefined
): VehicleCategoryKey | undefined => {
  const global_entity_type = window.BB.entity?.type;
  const is_train_entity: boolean =
    !!global_entity_type &&
    landing_pages_entities_trains_types.includes(global_entity_type);
  return is_train_entity ? VehicleType.Train : state_vehicle_category;
};

export const redirectToResultsPage = (
  options: ResultsPageRedirectOptions
): void => {
  const vehicle_category = getVehicleCategory(options.state.vehicle_category);

  const results_page_url = getResultsPageUrl(options, {
    ...options.state,
    vehicle_category
  });
  redirect(results_page_url);
};

export const ancillaryRedirectAffiliateAndShowBusbudResults = async (
  affiliate_name: AffiliatePartners,
  options: ResultsPageRedirectOptions
): Promise<void> => {
  const { state, tracker } = options;

  try {
    const url = getSearchAffiliateQuery({
      ...state,
      affiliate_name
    });

    const is_target_available = await isRedirectTargetAvailable(url);

    if (is_target_available) {
      tracker?.track(
        redirectToAffiliatePartner(affiliate_name, "searchbox_pop_under"),
        () => {
          // Opens the results page in a new tab
          openToResultsPage(options);
          // Redirect to affiliate in the current tab
          redirect(url);
        }
      );
    } else {
      throw new Error("Affiliate redirection failed");
    }
  } catch {
    // Redirects to the results page on the same tab
    redirectToResultsPage(options);
  }
};
