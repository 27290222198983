import React from "react";

import { useSpinDelay } from "spin-delay";

import { IconSpinner } from "@busbud/horizon";

import {
  AUTOCOMPLETE_SPINNER_DELAY_MS,
  AUTOCOMPLETE_SPINNER_MIN_DURATION_MS
} from "@app/modules/search/constants";

/**
 * The hook used to know if we should display the spinner tends to trigger a lot of rerenders.
 * So to avoid rerendering the whole component tree, we isolate this hook in its own component,
 *
 * @param children.children
 * @param children
 * @param isFetching
 * @param children.isFetching
 * @class
 */
export const WithSpinner: React.FC<{
  isFetching: boolean;
  children: React.ReactNode;
}> = ({ children, isFetching }) => {
  const show_spinner = useSpinDelay(isFetching, {
    delay: AUTOCOMPLETE_SPINNER_DELAY_MS,
    minDuration: AUTOCOMPLETE_SPINNER_MIN_DURATION_MS
  });

  return (
    <>
      {children}
      {show_spinner ? (
        <IconSpinner
          size="lg"
          className="mx-auto my-200 animate-spin text-icon-color-secondary"
        />
      ) : null}
    </>
  );
};
