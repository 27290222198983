import React from "react";

import { PlaceType } from "@busbud/core-types/dist/lib/flex";

import { ListBox } from "@app/components/listbox";
import { getSuggestionLocationIcon } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/autocomplete-option/autocomplete-icons";
import { NapiFlexLocationOption } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-napi-predictions";
import { useLiteAppContext } from "@app/helpers/hooks";
import {
  SuggestionIcon,
  SuggestionIconType
} from "@app/modules/search/suggestion/suggestion-icon";
import { Features } from "@app/types/experiments";

interface CitySuggestionItemProps extends React.HTMLAttributes<HTMLDivElement> {
  suggestion: NapiFlexLocationOption;
  isActive: boolean;
}

const getTitle = (
  suggestion: NapiFlexLocationOption,
  features: Partial<Features>
): {
  title: string;
  subtitle: string | undefined;
  detail: string | undefined;
} => {
  const { full_name, location } = suggestion.value;
  const [city_name, ...region_name] = full_name.split(",");

  if (
    features.SUGGESTIONS_POI_TEMPLATE &&
    location &&
    location.place_type === PlaceType.PointOfInterest
  ) {
    return {
      title: location.full_name,
      subtitle: undefined,
      detail: full_name
    };
  }

  return {
    title: city_name,
    subtitle: region_name.join(", "),
    detail: location?.full_name
  };
};

export const CitySuggestionItem: React.FC<CitySuggestionItemProps> = ({
  suggestion,
  ...props
}) => {
  const { features } = useLiteAppContext();
  const { title, subtitle, detail } = getTitle(suggestion, features);
  const { location } = suggestion.value;

  let icon: SuggestionIconType = "city";

  if (location) {
    icon = getSuggestionLocationIcon(location.stop_type);
  }

  return (
    <ListBox.Option
      iconStart={<SuggestionIcon type={icon} hidden={false} />}
      title={title}
      subtitle={subtitle}
      detail={detail}
      {...props}
    />
  );
};
