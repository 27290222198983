import { IsoDate, Month } from "@app/components/calendar/Calendar.types";

export function generateMonth(
  date: IsoDate,
  month_offset: number,
  getMonthYear: (date: IsoDate) => string,
  getWeekArray: (date: IsoDate) => IsoDate[][]
): Month {
  const curr_date = new Date(date);
  const year =
    curr_date.getUTCFullYear() +
    Math.floor((curr_date.getUTCMonth() + month_offset) / 12);
  const month = ((curr_date.getUTCMonth() + month_offset) % 12) + 1;
  const firstDayOfMonth = `${year}-${month.toString().padStart(2, "0")}-01`;

  return {
    label: getMonthYear(firstDayOfMonth),
    weeks: getWeekArray(firstDayOfMonth),
    number: month,
    year
  };
}
