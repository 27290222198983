import type { SentryOptions } from "@app/services/sentry-facade";

import type { AxiosError } from "axios";

export async function lazySentryCaptureException(
  original_err: Error | AxiosError,
  options: SentryOptions = {}
): Promise<void> {
  import("../services/sentry-facade").then(({ SentryFacade }) => {
    SentryFacade.captureException(original_err, options);
  });
}
