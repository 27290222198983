import { useQuery } from "@tanstack/react-query";

import { getFormattedSuggestionName } from "@app/components/search-form/helpers";
import {
  getFormattedQueryParams,
  getNapiQueryParams,
  getOriginPlaceIds,
  getSearchTypes,
  isSearchTypeInvalid
} from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/flex-query-params";
import {
  LocationStateValues,
  useOriginLocation
} from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-search-state-hooks";
import { trackInvalidCityError } from "@app/components/search-form-hydrated/search-form-errors";
import { useLiteAppContext } from "@app/helpers/hooks";
import { PlacesResponse } from "@app/modules/search/autocomplete/autocomplete-client";
import {
  formatSuggestions,
  FormattedSuggestion
} from "@app/modules/search/helpers/format-suggestion";
import { Features } from "@app/types/experiments";
import { SearchLocationType, SearchPlace } from "@app/types/search-types";

import { SupportedLocale } from "@config/locales";

const FlexEndpoints = {
  PointOfInterestSuggestion: "/flex/suggestions/points-of-interest"
};

const ACCEPT_HEADER_V2 =
  "application/vnd.busbud+json; version=2; profile=https://schema.busbud.com/v2/anything.json";

export interface NapiFlexLocationOption {
  value: FormattedSuggestion;
  label: string;
  id: string;
}

export interface AutocompletePredictionDataset {
  location_options: NapiFlexLocationOption[];
}

interface GetNapiQueryPredictionsParams {
  input_type: SearchLocationType;
  query: string;
  features: Partial<Features>;
  locale: SupportedLocale;
}

const getNapiQueryPredictions = async ({
  input_type,
  query,
  features,
  locale
}: GetNapiQueryPredictionsParams) => {
  if (typeof window === "undefined") {
    throw new Error(
      "getNapiQueryPredictions should only be called in the browser"
    );
  }

  const napi_url = window.BB.NAPI_URL; // TODO - move to context
  const url = `${napi_url}${FlexEndpoints.PointOfInterestSuggestion}`;
  const search_state = window.store.getState()
    .search_form as LocationStateValues;

  const { latitude, longitude } = window.BB.search_form; // TODO - move to context
  const is_geo_available: boolean = !!latitude && !!longitude;

  const search_types = getSearchTypes({
    query,
    input_type,
    search_state,
    is_geo_available,
    features
  });

  if (isSearchTypeInvalid(search_types)) {
    return {
      formatted_suggestions: [],
      search_types,
      number_of_suggestions: 0
    };
  }

  const features_params: string[] = [];

  /** enables full text search as the primary search for flex **/
  if (features.ENABLE_KEYWORD_PRIMARY_SEARCH) {
    features_params.push(
      `${encodeURIComponent("keyword_primary_search")}=${encodeURIComponent(
        true
      )}`
    );
  }

  /** enables search via query parameter **/
  if (features.AUTO_COMPLETE_SUGGESTIONS_INCREASE) {
    features_params.push(
      `${encodeURIComponent("use_route_metrics")}=${encodeURIComponent(true)}`
    );
  }

  /** removes locations from autocomplete **/
  features_params.push(
    `${encodeURIComponent("exclude_locations")}=${encodeURIComponent(true)}`
  );

  const params = getNapiQueryParams({
    query,
    input_type,
    search_types,
    search_state,
    lang: window.BB.config.lang, // TODO - move to context
    locale,
    latitude,
    longitude,
    features
  });

  const qs = getFormattedQueryParams(params).concat(features_params).join("&");

  const data = await fetch(`${url}?${qs}`, {
    headers: {
      Accept: ACCEPT_HEADER_V2,
      "x-busbud-token": window.BB.NAPI_AUTH_TOKEN, // TODO - move to context
      "x-request-id": window.BB.inst.request_id // TODO - move to context
    }
  }).then(response => response.json() as Promise<PlacesResponse>);
  const suggestions: NapiFlexLocationOption[] = formatSuggestions(data).map(
    formatted_suggestion => ({
      id: getFormattedPlaceUniqueId(formatted_suggestion),
      value: formatted_suggestion,
      label: getFormattedSuggestionName(formatted_suggestion)
    })
  );

  if (suggestions.length === 0) {
    trackInvalidCityError({
      query,
      search_types,
      latitude,
      longitude
    });
  }

  return {
    formatted_suggestions: suggestions,
    search_types,
    number_of_suggestions: suggestions.length
  };
};

export const fallback_autocomplete_data: AutocompletePredictionDataset = {
  location_options: []
};

interface PredictionQueryKeyParams {
  input_type: SearchLocationType;
  query: string;
  origin_search_place: SearchPlace | null;
}

const getPredictionQueryKey = ({
  input_type,
  query,
  origin_search_place
}: PredictionQueryKeyParams): [string, SearchLocationType, string, string] => {
  const is_destination_autocomplete = input_type === "destination";
  let origin_ids = "";

  if (is_destination_autocomplete) {
    const { origin_id, origin_child_id } =
      getOriginPlaceIds(origin_search_place);
    origin_ids = `${origin_id}:${origin_child_id}`;
  }

  return ["autosuggestions", input_type, query, origin_ids];
};

export const useNapiPredictions = (
  input_type: SearchLocationType,
  query: string
) => {
  const { features, locale } = useLiteAppContext();
  const origin = useOriginLocation();

  return useQuery<AutocompletePredictionDataset>({
    queryKey: getPredictionQueryKey({
      input_type,
      query,
      origin_search_place: origin
    }),
    queryFn: async (): Promise<AutocompletePredictionDataset> => {
      const { formatted_suggestions } = await getNapiQueryPredictions({
        input_type,
        query,
        features,
        locale
      });
      return {
        location_options: formatted_suggestions
      };
    },
    placeholderData: (
      previousData,
      _previousQuery
    ): AutocompletePredictionDataset =>
      previousData || fallback_autocomplete_data
  });
};

export function getFormattedPlaceUniqueId(place: FormattedSuggestion): string {
  return place.location?.geo_entity_id ?? place.geo_entity_id ?? place.id;
}
